import { Flex, ThemeUICSSObject } from 'theme-ui';
import { Tooltip } from '@odekoteam/doppio';
import { ReactNode } from 'react';

const iconBackground: ThemeUICSSObject = {
  padding: '4px',
  borderRadius: '4px',
  alignItems: 'center',
  backgroundColor: 'backgroundMedium',
};

const iconCountStyle: ThemeUICSSObject = {
  marginLeft: '5px',
  variant: 'text.labelSm',
  color: 'secondaryText',
};

const iconStyle: ThemeUICSSObject = {
  alignItems: 'center',
};

const BaseIcon = ({
  count,
  style = {},
  children,
  testId,
  labelText,
}: {
  count?: number;
  style?: ThemeUICSSObject;
  black?: boolean;
  testId?: string;
  children: ReactNode;
  labelText: string;
}): JSX.Element | null => {
  if (count === 0) return null;

  return (
    <Tooltip labelText={labelText} placement="bottom">
      <Flex
        data-test-id={testId}
        sx={{ ...iconStyle, ...(count ? iconBackground : {}), ...style }}
      >
        {children}
        {count && <span sx={iconCountStyle}>{count}</span>}
      </Flex>
    </Tooltip>
  );
};

export default BaseIcon;
