import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { MOVE_DELIVERY_STOPS_TO_ROUTE } from 'src/graphql/requests/mutations';
import { client } from 'src/graphql/client';
import {
  Route,
  DeliveryStopsMoveToRoutePayload,
} from 'src/graphql/types/delivery';
import {
  GDeliveryStopsMoveToRouteInput,
  GMoveDeliveryStopsToRouteMutation,
} from 'src/graphql/types/generated';
import { NotificationType, showNotification } from '../notification';
import { DeliveryState } from './state';
import {
  CANNOT_CHANGE_DATA,
  INVALID_NIGHT_END,
  isUserErrorInArray,
  updateSequenceNumbersRedelivery,
} from 'src/utils';

export const moveDeliveryStopsToRoute = createAsyncThunk(
  'delivery/moveDeliveryStopsToRoute',
  async (
    { routeId, stopIds, redelivery }: GDeliveryStopsMoveToRouteInput,
    thunkAPI,
  ): Promise<DeliveryStopsMoveToRoutePayload | null> => {
    const { data, error } = await client
      .mutation<GMoveDeliveryStopsToRouteMutation>(
        MOVE_DELIVERY_STOPS_TO_ROUTE,
        {
          input: { routeId, stopIds, redelivery },
        },
      )
      .toPromise();
    if (
      error ||
      !data?.moveDeliveryStopsToRoute ||
      data.moveDeliveryStopsToRoute.userErrors.length > 0
    ) {
      const isInvalidNightEnd = isUserErrorInArray(
        INVALID_NIGHT_END,
        data?.moveDeliveryStopsToRoute?.userErrors,
      );
      const message = isInvalidNightEnd ? CANNOT_CHANGE_DATA : undefined;
      thunkAPI.dispatch(
        showNotification({
          type: NotificationType.Error,
          message,
        }),
      );
      return null;
    }
    thunkAPI.dispatch(
      showNotification({
        type: NotificationType.Success,
        message: `Moved stop(s) successfully`,
      }),
    );
    const moveDeliveryStopsToRoute: DeliveryStopsMoveToRoutePayload =
      data.moveDeliveryStopsToRoute;
    return moveDeliveryStopsToRoute;
  },
);

export const moveDeliveryStopsToRouteFulfilled = (
  state: DeliveryState,
  action: PayloadAction<DeliveryStopsMoveToRoutePayload | null>,
): void => {
  state.changeRouteEditor.isSaving = false;
  if (action?.payload?.updatedRoutes) {
    state.secondaryCardVisible = undefined;

    // update redelivery stop sequence numbers
    const updatedRoutes = action.payload?.updatedRoutes.map(
      updateSequenceNumbersRedelivery,
    );
    const currentRoutesState = state.routes ? [...state.routes] : [];

    // Update routes
    state.routes = currentRoutesState.map((route: Route): Route => {
      // update the route in the routes array via replacement
      const myUpdatedRoute = updatedRoutes.find(
        (updatedRoute) => updatedRoute.id === route.id,
      );
      if (myUpdatedRoute) {
        return myUpdatedRoute;
      }
      return route;
    });
    state.changeRouteEditor.selectedStops = [];
  }
};

export const moveDeliveryStopsToRoutePending = (state: DeliveryState): void => {
  state.changeRouteEditor.isSaving = true;
};
