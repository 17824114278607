import { ThemeUICSSObject } from 'theme-ui';

export const mapCardMaxHeight: ThemeUICSSObject = {
  maxHeight: ['calc(100vh - 270px)', 'calc(100vh - 194px)'],
};

// Note: Setting `overflowY: hidden` will cause a (dev-only) console error to appear:
// `react-beautiful-dnd Droppable: unsupported nested scroll container detected.`
// This is unavoidable, won't be fixed by `react-beautiful-dnd` anytime soon
// but has no discernible affect on the ui.
//
// This is required for the design.
// @see https://github.com/atlassian/react-beautiful-dnd/issues/131
export const mapCard: ThemeUICSSObject = {
  alignSelf: 'flex-start',
  backgroundColor: 'white',
  zIndex: 10,
  margin: '12px',
  borderRadius: '8px',
  padding: '24px 10px 12px 10px',
  boxShadow: 'standard',
  width: ['calc(100vw - 42px)', 'auto'],
  minWidth: ['0', '416px'],
  height: 'auto',
  ...mapCardMaxHeight,
  overflowY: 'hidden',
  transition: 'all .5s ease-in-out',
};

export const innerMapCard: ThemeUICSSObject = {
  display: 'flex',
  flexDirection: 'column',
  margin: '12px',
  maxHeight: 'calc(100% - 24px)',
};

export const primaryCardHeaderContainer: ThemeUICSSObject = {
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};

export const priamryCardHeaderArrow: ThemeUICSSObject = {
  alignSelf: 'flex-start',
  paddingTop: '4px',
  paddingRight: '14px',
  paddingLeft: '7px',
  '&:hover': {
    cursor: 'pointer',
  },
};

export const mapCardSecondary: ThemeUICSSObject = {
  padding: '24px',
  marginLeft: '0',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'start',
  width: ['100%', '388px'],
  position: 'relative',
};

export const headerLabelBack: ThemeUICSSObject = {
  fontFamily: 'heading',
  fontSize: 4,
  marginLeft: '8px',
  marginRight: '12px',
};

export const row: ThemeUICSSObject = {
  padding: '8px',
};

export const header: ThemeUICSSObject = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'left',
  alignItems: 'center',
};

export const headerLabel: ThemeUICSSObject = {
  fontFamily: 'heading',
  fontSize: 3,
};

export const actions: ThemeUICSSObject = {
  display: 'flex',
  alignItems: 'center',
};
