import { ThemeUICSSObject } from 'theme-ui';
import IconFrozen from 'assets/iconFrozen.svg';
import IconFrozenBlack from 'assets/iconFrozenBlack.svg';
import BaseIcon from './BaseIcon';

const FreezerRequiredIcon = ({
  black,
  testId,
  ...rest
}: {
  count?: number;
  style?: ThemeUICSSObject;
  black?: boolean;
  testId?: string;
}): JSX.Element | null => {
  return (
    <BaseIcon
      {...rest}
      testId={`frozen-icon-${testId}`}
      labelText="Requires freezer"
    >
      {black ? <IconFrozenBlack /> : <IconFrozen />}
    </BaseIcon>
  );
};

export default FreezerRequiredIcon;
