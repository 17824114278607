import { ThemeUICSSObject } from 'theme-ui';
import IconRefrigeration from 'assets/iconRefrigerated.svg';
import IconRefrigerationBlack from 'assets/iconRefrigeratedBlack.svg';
import BaseIcon from './BaseIcon';

const RefrigerationRequiredIcon = ({
  black,
  testId,
  ...rest
}: {
  count?: number;
  style?: ThemeUICSSObject;
  black?: boolean;
  testId?: string;
}): JSX.Element | null => {
  return (
    <BaseIcon
      {...rest}
      testId={`refrigeration-icon-${testId}`}
      labelText="Requires refrigeration"
    >
      {black ? <IconRefrigerationBlack /> : <IconRefrigeration />}
    </BaseIcon>
  );
};

export default RefrigerationRequiredIcon;
