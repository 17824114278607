import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Delivery,
  GetOrderNode,
  PickUp,
  Redelivery,
  RoutingCohort,
} from 'src/graphql/types/delivery';
import {
  setRouteDetails,
  setRouteDetailsFulfilled,
  setRouteDetailsPending,
  setRouteDetailsRejected,
} from './setRouteDetails';
import {
  getWarehouses,
  getWarehousesFulfilled,
  getWarehousesPending,
  getWarehousesRejected,
} from './getWarehouses';
import {
  getRoutes,
  getRoutesFulfilled,
  getRoutesPending,
  getRoutesRejected,
} from './getRoutes';
import {
  setSelectedWarehouseId,
  setSelectedWarehouseIdFulfilled,
} from './setSelectedWarehouseId';
import {
  deliveryStopsReorder,
  deliveryStopsReorderFulfilled,
  deliveryStopsReorderPending,
} from './deliveryStopsReorder';
import {
  moveDeliveryStopsToRoute,
  moveDeliveryStopsToRouteFulfilled,
  moveDeliveryStopsToRoutePending,
} from './moveDeliveryStopsToRoute';
import {
  createWarehouseDeliveryRoute,
  createWarehouseDeliveryRouteFulfillment,
} from './createWarehouseDeliveryRoute';
import {
  DeliveredQuantityEditor,
  DeliveryState,
  initialState,
  Mode,
  PrimaryCard,
  RouteListTab,
  SecondaryCard,
} from './state';

import { setNightEnd, setNightEndFulfilled } from './setNightEnd';
import {
  getRouteImportInfo,
  getRouteImportInfoFulfilled,
  getRouteImportInfoPending,
  getRouteImportInfoRejected,
} from './getRouteImportInfo';
import {
  updateTruckCount,
  updateTruckCountFulfillment,
} from './updateTruckCount';
import {
  getAllStopStatuses,
  getAllStopStatusesFulfilled,
  getAllStopStatusesPending,
  getAllStopStatusesRejected,
} from './getAllStopStatues';
import {
  getPrimaryStopStatuses,
  getPrimaryStopStatusesFulfilled,
  getPrimaryStopStatusesPending,
  getPrimaryStopStatusesRejected,
} from './getPrimaryStopStatuses';
import { doesStopHaveInfo } from 'src/utils/secondaryStopStatus';
import {
  getThirdPartyOrders,
  getThirdPartyOrdersFulfilled,
  getThirdPartyOrdersPending,
  getThirdPartyOrdersRejected,
} from './getThirdPartyOrders';

export const deliverySlice = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    setSelectedRouteId: (
      state: DeliveryState,
      action: PayloadAction<{ id: string | null; hover?: boolean }>,
    ) => {
      return {
        ...state,
        selectedRouteId: {
          id: action.payload.id,
          hover: action.payload.hover ?? false,
        },
      };
    },
    setSelectedCohortId: (
      state: DeliveryState,
      action: PayloadAction<{ id: string | null; hover?: boolean }>,
    ) => {
      return {
        ...state,
        selectedCohortId: {
          id: action.payload.id,
          hover: action.payload.hover ?? false,
        },
      };
    },
    setRouteListCardVisible: (state: DeliveryState) => {
      return {
        ...state,
        primaryCard: {
          activeCard: PrimaryCard.RouteListCard,
        },
        secondaryCardVisible: undefined,
        stopDetails: {
          ...state.stopDetails,
          selectedStop: undefined,
        },
        selectedRouteId: {
          id: null,
          hover: false,
        },
        selectedOrderId: undefined,
        mode: Mode.ViewRoutes,
      };
    },
    setRouteDetailsCardVisible: (
      state: DeliveryState,
      action: PayloadAction<string | null>,
    ) => {
      return {
        ...state,
        primaryCard: {
          activeCard: PrimaryCard.RouteDetailsCard,
        },
        secondaryCardVisible: undefined,
        selectedRouteId: {
          id: action.payload,
          hover: false,
        },
        stopDetails: {
          selectedStop: undefined,
        },
        selectedOrderId: undefined,
      };
    },
    setSelectedStopsChangeRoute: (
      state: DeliveryState,
      action: PayloadAction<(Delivery | Redelivery)[]>,
    ) => {
      return {
        ...state,
        changeRouteEditor: {
          ...state.changeRouteEditor,
          selectedStops: action.payload,
        },
      };
    },
    setEditLocationCohortSecondaryCardVisible: (
      state: DeliveryState,
      action: PayloadAction<{
        locationId?: string;
        businessName?: string;
        routeId?: string;
        routingCohort?: RoutingCohort;
        isRoutingCohort: boolean;
      }>,
    ) => {
      const {
        locationId,
        businessName,
        routeId,
        isRoutingCohort,
        routingCohort,
      } = action.payload;
      return {
        ...state,
        secondaryCardVisible: SecondaryCard.EditLocationCohort,
        locationCohortEditor: {
          ...state.locationCohortEditor,
          businessName,
          selectedLocationId: locationId,
          routingCohort,
        },
        primaryCard: {
          activeCard: isRoutingCohort
            ? PrimaryCard.CohortDetailsCard
            : PrimaryCard.RouteDetailsCard,
        },
        selectedRouteId: {
          id: routeId ?? null,
          hover: false,
        },
        stopDetails: {
          selectedStop: undefined,
        },
        selectedOrderId: undefined,
        selectedCohortId: {
          id: routingCohort?.id ?? null,
          hover: false,
        },
      };
    },
    setStopDetailsCardVisible: (
      state: DeliveryState,
      action: PayloadAction<{
        stop: Delivery | PickUp | Redelivery;
        routeId: string | null;
        cohortId: string | null;
      }>,
    ) => {
      return {
        ...state,
        changeRouteEditor: {
          ...state.changeRouteEditor,
          selectedStops: [],
        },
        primaryCard: {
          activeCard: PrimaryCard.StopDetailsCard,
        },
        secondaryCardVisible: undefined,
        stopDetails: {
          ...state.stopDetails,
          selectedStop: action.payload.stop,
        },
        selectedRouteId: {
          id: action.payload.routeId,
          hover: false,
        },
        selectedOrderId: undefined,
        selectedCohortId: {
          id: action.payload.cohortId,
          hover: false,
        },
      };
    },
    setRouteListEditRouteImportVisible: (state: DeliveryState) => {
      return {
        ...state,
        secondaryCardVisible: SecondaryCard.RouteListEditRouteImport,
      };
    },
    setEditDeliveredQuantityCardVisible: (
      state: DeliveryState,
      action: PayloadAction<DeliveredQuantityEditor>,
    ) => {
      return {
        ...state,
        secondaryCardVisible: SecondaryCard.StopDetailsEditDeliveredQuantity,
        deliveredQuantityEditor: action.payload,
      };
    },
    setOrderDetailsCardVisible: (
      state: DeliveryState,
      action: PayloadAction<string>,
    ) => {
      return {
        ...state,
        selectedOrderId: action.payload,
        primaryCard: {
          activeCard: PrimaryCard.OrderDetailsCard,
        },
        secondaryCardVisible: undefined,
      };
    },
    setCohortListCardVisible: (state: DeliveryState) => {
      return {
        ...state,
        primaryCard: {
          activeCard: PrimaryCard.CohortListCard,
        },
        secondaryCardVisible: undefined,
        stopDetails: {
          ...state.stopDetails,
          selectedStop: undefined,
        },
        selectedRouteId: {
          id: null,
          hover: false,
        },
        selectedOrderId: undefined,
        selectedCohortId: {
          id: null,
          hover: false,
        },
        mode: Mode.ViewCohorts,
      };
    },
    setCohortDetailsCardVisible: (
      state: DeliveryState,
      action: PayloadAction<string | null>,
    ) => {
      return {
        ...state,
        primaryCard: {
          activeCard: PrimaryCard.CohortDetailsCard,
        },
        secondaryCardVisible: undefined,
        selectedCohortId: {
          id: action.payload,
          hover: false,
        },
        stopDetails: {
          selectedStop: undefined,
        },
        selectedOrderId: undefined,
      };
    },
    setBulkEditRouteCardVisible: (state: DeliveryState) => {
      return {
        ...state,
        primaryCard: {
          activeCard: PrimaryCard.BulkEditRouteCard,
        },
        secondaryCardVisible: undefined,
        stopDetails: {
          ...state.stopDetails,
          selectedStop: undefined,
        },
        selectedRouteId: {
          id: null,
          hover: false,
        },
        selectedOrderId: undefined,
        selectedCohortId: {
          id: null,
          hover: false,
        },
        mode: Mode.EditRoutes,
        changeRouteEditor: {
          selectedStops: [],
          isSaving: false,
        },
      };
    },
    setSecondaryCardVisible: (
      state: DeliveryState,
      action: PayloadAction<SecondaryCard | undefined>,
    ) => {
      return {
        ...state,
        secondaryCardVisible: action.payload,
      };
    },
    closeSecondaryCards: (state: DeliveryState) => {
      return {
        ...state,
        secondaryCardVisible: undefined,
      };
    },
    setIsUpdateTruckMutationLoading: (
      state: DeliveryState,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        routeImport: {
          ...state.routeImport,
          isUpdateTruckMutationLoading: action.payload,
        },
      };
    },
    showPopover: (
      state: DeliveryState,
      action: PayloadAction<{
        stop?: Delivery | PickUp | Redelivery;
        order?: GetOrderNode;
      }>,
    ) => {
      return {
        ...state,
        popover: {
          visible: true,
          stop: action.payload.stop,
          order: action.payload.order,
        },
      };
    },
    hidePopover: (state: DeliveryState) => {
      return {
        ...state,
        popover: {
          visible: false,
          stop: undefined,
        },
      };
    },
    showPrimaryCardContent: (state: DeliveryState): DeliveryState => {
      return {
        ...state,
        primaryCard: {
          ...state.primaryCard,
          contentVisible: true,
        },
      };
    },
    hidePrimaryCardContent: (state: DeliveryState): DeliveryState => {
      return {
        ...state,
        primaryCard: {
          ...state.primaryCard,
          contentVisible: false,
        },
        secondaryCardVisible: undefined,
      };
    },
    updateSecondaryStatusHasWarehouseNote: (
      state: DeliveryState,
      action: PayloadAction<{
        routeId: string;
        stopId: string;
        hasWarehouseNote: boolean;
      }>,
    ): DeliveryState => {
      const routeStatuses = [...state.deliveryStatuses.routeStatuses];
      const stopStatuses = [...state.deliveryStatuses.stopStatuses];
      const redeliveryStopStatuses = [
        ...state.deliveryStatuses.redeliveryStopStatuses,
      ];
      const routeIndex = routeStatuses.findIndex(
        (routeStatus) => routeStatus.routeId === action.payload.routeId,
      );
      const stopIndex = stopStatuses.findIndex(
        (stopStatus) => stopStatus.stopId === action.payload.stopId,
      );
      if (stopStatuses[stopIndex]) {
        stopStatuses[stopIndex] = {
          ...stopStatuses[stopIndex],
          stopSecondaryStatus: {
            ...stopStatuses[stopIndex].stopSecondaryStatus,
            hasWarehouseNote: action.payload.hasWarehouseNote,
          },
        };
      }
      const hasStopWithInfo =
        doesStopHaveInfo(stopStatuses) ||
        doesStopHaveInfo(redeliveryStopStatuses);
      routeStatuses[routeIndex] = {
        ...routeStatuses[routeIndex],
        hasSecondaryStatuses: hasStopWithInfo,
      };
      return {
        ...state,
        deliveryStatuses: {
          ...state.deliveryStatuses,
          routeStatuses,
          stopStatuses,
        },
      };
    },
    updateSecondaryStatusHasReminder: (
      state: DeliveryState,
      action: PayloadAction<{
        routeId: string;
        stopId: string;
        hasReminder: boolean;
      }>,
    ): DeliveryState => {
      const routeStatuses = [...state.deliveryStatuses.routeStatuses];
      const stopStatuses = [...state.deliveryStatuses.stopStatuses];
      const redeliveryStopStatuses = [
        ...state.deliveryStatuses.redeliveryStopStatuses,
      ];
      const routeIndex = routeStatuses.findIndex(
        (routeStatus) => routeStatus.routeId === action.payload.routeId,
      );
      const stopIndex = stopStatuses.findIndex(
        (stopStatus) => stopStatus.stopId === action.payload.stopId,
      );
      if (stopStatuses[stopIndex]) {
        stopStatuses[stopIndex] = {
          ...stopStatuses[stopIndex],
          stopSecondaryStatus: {
            ...stopStatuses[stopIndex].stopSecondaryStatus,
            hasReminder: action.payload.hasReminder,
          },
        };
      }
      const hasStopWithInfo =
        doesStopHaveInfo(stopStatuses) ||
        doesStopHaveInfo(redeliveryStopStatuses);
      routeStatuses[routeIndex] = {
        ...routeStatuses[routeIndex],
        hasSecondaryStatuses: hasStopWithInfo,
      };
      return {
        ...state,
        deliveryStatuses: {
          ...state.deliveryStatuses,
          routeStatuses,
          stopStatuses,
        },
      };
    },
    setActiveRouteListTab: (
      state: DeliveryState,
      action: PayloadAction<RouteListTab>,
    ) => {
      return {
        ...state,
        selectedRouteListTab: action.payload,
      };
    },
    setCohortViewVisible: (state: DeliveryState) => {
      return {
        ...state,
        mode: Mode.ViewCohorts,
      };
    },
    updateRouteOriginInState: (
      state: DeliveryState,
      action: PayloadAction<{ routeId: string; routeOrigin: string }>,
    ) => {
      const currentRoutesState = state.routes ? [...state.routes] : [];
      const updatedRouteId = action.payload.routeId;

      state.routes = currentRoutesState.map((route) => {
        // update the route in the routes array via replacement
        if (route.id === updatedRouteId) {
          return {
            ...route,
            routeOrigin: action.payload.routeOrigin,
          };
        }
        return route;
      });
    },
    updateLocationCohortInState: (
      state: DeliveryState,
      action: PayloadAction<{
        locationId: string;
        routingCohort: RoutingCohort;
      }>,
    ) => {
      const currentRoutesState = state.routes ? [...state.routes] : [];
      const { locationId, routingCohort } = action.payload;

      state.routes = currentRoutesState.map((route) => {
        const updatedStops = route.stops?.map((stop) => {
          if (stop.__typename === 'PickUp') return stop;
          if (stop.location?.id === locationId)
            stop.location.routingCohort = routingCohort;
          return stop;
        });
        route.stops = updatedStops;
        return route;
      });
    },
    setMapIconFilters: (
      state: DeliveryState,
      action: PayloadAction<{
        displayBienCuit?: boolean;
        displayPastry?: boolean;
        displayDayNight?: boolean;
        displayRefrigeration?: boolean;
      }>,
    ) => {
      return {
        ...state,
        mapIconFilters: {
          ...state.mapIconFilters,
          ...action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    // ------------ getWarehouses ------------
    builder.addCase(getWarehouses.fulfilled, getWarehousesFulfilled);
    builder.addCase(getWarehouses.pending, getWarehousesPending);
    builder.addCase(getWarehouses.rejected, getWarehousesRejected);

    // ------------ getRoutes ------------
    builder.addCase(getRoutes.fulfilled, getRoutesFulfilled);
    builder.addCase(getRoutes.pending, getRoutesPending);
    builder.addCase(getRoutes.rejected, getRoutesRejected);

    // ------------ setSelectedWarehouseId ------------
    builder.addCase(
      setSelectedWarehouseId.fulfilled,
      setSelectedWarehouseIdFulfilled,
    );

    // ------------ setRouteDetails ------------
    builder.addCase(setRouteDetails.pending, setRouteDetailsPending);
    builder.addCase(setRouteDetails.fulfilled, setRouteDetailsFulfilled);
    builder.addCase(setRouteDetails.rejected, setRouteDetailsRejected);

    // ------------ deliveryStopsReorder ------------
    builder.addCase(
      deliveryStopsReorder.fulfilled,
      deliveryStopsReorderFulfilled,
    );
    builder.addCase(deliveryStopsReorder.pending, deliveryStopsReorderPending);

    // ------------ moveDeliveryStopsToRoute ------------
    builder.addCase(
      moveDeliveryStopsToRoute.fulfilled,
      moveDeliveryStopsToRouteFulfilled,
    );
    builder.addCase(
      moveDeliveryStopsToRoute.pending,
      moveDeliveryStopsToRoutePending,
    );

    // ------------ createWarehouseDeliveryRoute ------------
    builder.addCase(
      createWarehouseDeliveryRoute.pending,
      (state: DeliveryState) => {
        state.createWarehouseDeliveryRoute.isSaving = true;
      },
    );
    builder.addCase(
      createWarehouseDeliveryRoute.fulfilled,
      createWarehouseDeliveryRouteFulfillment,
    );

    // ------------ setNightEnd ------------
    builder.addCase(setNightEnd.fulfilled, setNightEndFulfilled);

    // ------------ getRouteImportInfo ------------
    builder.addCase(getRouteImportInfo.fulfilled, getRouteImportInfoFulfilled);
    builder.addCase(getRouteImportInfo.pending, getRouteImportInfoPending);
    builder.addCase(getRouteImportInfo.rejected, getRouteImportInfoRejected);

    // ------------ updateTruckCount ------------
    builder.addCase(updateTruckCount.fulfilled, updateTruckCountFulfillment);

    // ------------ getAllStopStatuses ------------
    builder.addCase(getAllStopStatuses.fulfilled, getAllStopStatusesFulfilled);
    builder.addCase(getAllStopStatuses.pending, getAllStopStatusesPending);
    builder.addCase(getAllStopStatuses.rejected, getAllStopStatusesRejected);

    // ------------ getPrimaryStopStatuses ------------
    builder.addCase(
      getPrimaryStopStatuses.fulfilled,
      getPrimaryStopStatusesFulfilled,
    );
    builder.addCase(
      getPrimaryStopStatuses.pending,
      getPrimaryStopStatusesPending,
    );
    builder.addCase(
      getPrimaryStopStatuses.rejected,
      getPrimaryStopStatusesRejected,
    );

    // ------------ getThirdPartyOrders ------------
    builder.addCase(
      getThirdPartyOrders.fulfilled,
      getThirdPartyOrdersFulfilled,
    );
    builder.addCase(getThirdPartyOrders.pending, getThirdPartyOrdersPending);
    builder.addCase(getThirdPartyOrders.rejected, getThirdPartyOrdersRejected);
  },
});
