import { ThemeUICSSObject } from 'theme-ui';
import IconMoon from 'assets/iconMoon.svg';
import IconMoonBlack from 'assets/iconMoonBlack.svg';
import BaseIcon from './BaseIcon';

const NighttimeIcon = ({
  black,
  testId,
  ...rest
}: {
  count?: number;
  style?: ThemeUICSSObject;
  black?: boolean;
  testId?: string;
}): JSX.Element | null => {
  return (
    <BaseIcon
      {...rest}
      testId={`nighttime-icon-${testId}`}
      labelText="Nighttime delivery window"
    >
      {black ? <IconMoonBlack /> : <IconMoon />}
    </BaseIcon>
  );
};

export default NighttimeIcon;
