import { deliverySlice } from './slice';

export * from './createWarehouseDeliveryRoute';
export * from './deliveryStopsReorder';
export * from './getRoutes';
export * from './getWarehouses';
export * from './moveDeliveryStopsToRoute';
export * from './setRouteDetails';
export * from './setSelectedWarehouseId';
export * from './setNightEnd';
export * from './getAllStopStatues';
export * from './state';

const { reducer, actions } = deliverySlice;
export const {
  setSelectedRouteId,
  setSelectedCohortId,
  setRouteDetailsCardVisible,
  setSelectedStopsChangeRoute,
  setRouteListCardVisible,
  setStopDetailsCardVisible,
  setEditDeliveredQuantityCardVisible,
  setCohortListCardVisible,
  setCohortDetailsCardVisible,
  setSecondaryCardVisible,
  closeSecondaryCards,
  setRouteListEditRouteImportVisible,
  setIsUpdateTruckMutationLoading,
  showPopover,
  hidePopover,
  showPrimaryCardContent,
  hidePrimaryCardContent,
  updateSecondaryStatusHasWarehouseNote,
  updateSecondaryStatusHasReminder,
  setOrderDetailsCardVisible,
  setActiveRouteListTab,
  setCohortViewVisible,
  updateRouteOriginInState,
  setEditLocationCohortSecondaryCardVisible,
  updateLocationCohortInState,
  setBulkEditRouteCardVisible,
  setMapIconFilters,
} = actions;
export default reducer;
