import { ThemeUICSSObject } from 'theme-ui';
import IconSun from 'assets/iconSun.svg';
import IconSunBlack from 'assets/iconSunBlack.svg';
import BaseIcon from './BaseIcon';

const DaytimeIcon = ({
  black,
  testId,
  ...rest
}: {
  count?: number;
  style?: ThemeUICSSObject;
  black?: boolean;
  testId?: string;
}): JSX.Element | null => {
  return (
    <BaseIcon
      {...rest}
      testId={`daytime-icon-${testId}`}
      labelText="Daytime delivery window"
    >
      {black ? <IconSunBlack /> : <IconSun />}
    </BaseIcon>
  );
};

export default DaytimeIcon;
